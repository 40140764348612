import styled, { css } from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {
  IPaginationButtonWrapper,
  IPaginationButtonClickable,
  IButtonLink,
} from "./types"
import { GreenSpan, BlackSpan } from "../../../Global"

export const BtnGreenSpan = styled(GreenSpan)`
  font-weight: ${props => props.theme.fontWeight.bold};
`

export const BtnBlackSpan = styled(BlackSpan)`
  font-weight: ${props => props.theme.fontWeight.bold};
`

export const ButtonWrapper = styled.div<IPaginationButtonWrapper>`
  margin: ${props => (props.margin ? props.margin : "unset")};
  display: inline-block;
`

export const ButtonClickable = styled.div<IPaginationButtonClickable>`
  background-color: ${props =>
    props.type === "current" ? "gray" : props.theme.color.lightGreen};
  padding: 5px;
  transition: 0.3s;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  /*
  ${props =>
    props.type === "landing" &&
    css`
      background-color: ${props.theme.color.lightGreen};
    `}
  ${props =>
    props.type === "blog" &&
    css`
      background-color: transparent;
    `}
*/
  ${props =>
    props.type !== "current" &&
    css`
      & > span {
        transition: 0.5s;
      }

      &:hover {
        background-color: ${props => props.theme.color.darkestGreen};
        & > span {
          color: ${props =>
            props.type === "landing"
              ? props.theme.color.white
              : props.theme.color.lightGreen};
        }
      }
    `}
`
export const ButtonLink = styled(AnchorLink)<IButtonLink>`
  ${props =>
    props.type === "current" &&
    css`
      &:hover {
        cursor: default;
      }
    `}
`
