import React from "react"
import IPager from "./types"
import PaginationButton from "./PaginationButton"
import { FlexCenter } from "../../Global"

const Pager = ({ context, margin }: IPager) => {
  const pageList = [{ id: 1, slug: "/blog" }]
  const generateSlugs = () => {
    for (let i = 0; i < context.numberOfPages; i++) {
      if (i != 0) pageList[i] = { id: i + 1, slug: "/blog/" + (i + 1) }
    }
  }

  generateSlugs()
  return (
    <div style={margin ? { margin: margin }: {}}>
      <FlexCenter>
        {context.humanPageNumber != 1 && (
          <PaginationButton type="first" slug={"/blog/"} />
        )}
        {context.previousPagePath != "" && (
          <PaginationButton type="previous" slug={context.previousPagePath} />
        )}
        {pageList.map((b, idx) =>
          b.id === context.humanPageNumber ? (
            <PaginationButton key={idx} type="current" slug={"#"} id={b.id} />
          ) : (
            <PaginationButton
              key={idx}
              type="normal"
              slug={b.id !== 1 ? "/blog/" + b.id : "/blog/"}
              id={b.id}
            />
          )
        )}
        {context.nextPagePath != "" && (
          <PaginationButton type="next" slug={context.nextPagePath} />
        )}
        {context.humanPageNumber != context.numberOfPages && (
          <PaginationButton
            type="last"
            slug={"/blog/" + context.numberOfPages}
          />
        )}
      </FlexCenter>
    </div>
  )
}

export default Pager
