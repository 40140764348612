import * as React from "react"
import {
  BlogCardContainer,
  BlogCardWrapper,
  ImageWrapper,
  ContentWrapper,
  H3BlogTitle,
  P,
} from "./styles"
import { GatsbyImage } from "gatsby-plugin-image"
import IBlogCard from "./types"
import Button from "../../../UI/Button"

const BlogCard = ({ postData }: IBlogCard) => {
  return (
    <BlogCardContainer>
      <BlogCardWrapper>
        <ImageWrapper>
          <GatsbyImage
            image={postData.node.picture.gatsbyImageData}
            alt={postData.node.pictureAlt}
          />
        </ImageWrapper>
        <ContentWrapper>
          <H3BlogTitle>{postData.node.title}</H3BlogTitle>
          <P>{postData.node.shortDescription.shortDescription}</P>
          <div>
            <Button
              margin={"0 0 20px 0"}
              type="blog"
              slug={"blog/" + postData.node.slug + "#blog"}
            >
              Czytaj Więcej
            </Button>
          </div>
        </ContentWrapper>
      </BlogCardWrapper>
    </BlogCardContainer>
  )
}

export default BlogCard
