import styled from "styled-components"
import { FlexCenter, Container, FlexCol } from "../../../Global"

export const BlogCardContainer = styled(FlexCenter)`
  width: 100%;
`

export const BlogCardWrapper = styled(Container)`
  display: grid;
  grid-template-columns: 330px 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  @media (max-width: 730px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px;
    grid-template-areas: ". ";
  }
`
export const ImageWrapper = styled.div`
  padding: 20px 20px 20px 15px;
  & > div {
    height: 100%;
  }
`

export const ContentWrapper = styled(FlexCol)`
  margin: 0px 20px 0px 20px;
`

export const H3BlogTitle = styled.h3`
  margin-bottom: 0px;
  font-size: 36px;
  @media (max-width: 730px) {
    margin-top: 0px;
    font-size: 26px;
  }
`
export const P = styled.p`
  font-size: 18px;
`
