import * as React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Global/Head"
import BlogComponent from "../components/Sections/Blog"
import { graphql } from "gatsby"
import useIndexQuery from "../data/query"

export const blog = graphql`
  query BlogQuery($skip: Int!, $limit: Int!) {
    allContentfulC4FBlogPost(skip: $skip, limit: $limit) {
      edges {
        node {
          slug
          picture {
            gatsbyImageData
            title
          }
          title
          shortDescription {
            shortDescription
          }
          pictureAlt
        }
      }
    }
  }
`

const Blog = (props: any) => {
  const queryData = useIndexQuery()
  return (
    <Layout
      backgroundImage={
        queryData.allFile.edges[0].node.childImageSharp.gatsbyImageData
      }
    >
      <Head
        description="Blog" //todo
        pageTitle="Blog"
        keywords="platforma treningowa online, treningi online, "
      />
      <BlogComponent pageContext={props.pageContext} data={props.data} />
    </Layout>
  )
}

export default Blog
