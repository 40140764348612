import * as React from "react"
import { Container, Section, M } from "../../Global"
import Head from "../../Global/Head"
import BlogCard from "./BlogCard"
import IBlog from "./types"
import { BlogSectionTitle } from "./styles"
import Pager from "../../UI/Pager"

const BlogComponent = ({ pageContext, data }: IBlog) => {
  return (
    <>
      <Head
        description="Blog description" //todo
        pageTitle="Blog"
        keywords="software, oakfusion"
      />
      <Section id="blog">
        <Container>
          <M>
            <BlogSectionTitle>BLOG</BlogSectionTitle>
          </M>
        </Container>
      </Section>
      {data.allContentfulC4FBlogPost.edges.map((b: any, idx: number) => (
        <BlogCard key={idx} postData={b} />
      ))}
      <Section>
        <Container>
          <Pager context={pageContext} margin="23px 0 40px 0" />
        </Container>
      </Section>
    </>
  )
}

export default BlogComponent
